import React from "react";
import { Link } from "gatsby-plugin-intl";
import { Heading, Text, Box } from "@chakra-ui/core";
import StoryType from "./storyType";
import MaclicImage from "../images/maclicImage";
import { useColorWithoutMode } from "../../util/colorMode";
import { useIntl } from "gatsby-plugin-intl"
import { getAttributeTranslated } from "../../util/entities"

const StoryPreview = ({ story, ...rest }) => {
  const intl = useIntl();
  const { color, render } = useColorWithoutMode("white", "gray.700");
  const link = story.fields ? story.fields.slug : "#";
  return render && (
    <Link to={link}>
      <Box pos="relative" shadow="md" borderWidth="1px" {...rest}>
        <MaclicImage src={story.photo}/>
        <Box
          w="100%"
          pos="absolute"
          bottom="0"
          left="0"
          p={4}
          bg={color}
        >
          <Text mb={4} as="span" size="sm">
            {getAttributeTranslated(story, 'category', intl.locale)}
          </Text>
          <Heading as="h2" size="lg">
            {getAttributeTranslated(story, 'title', intl.locale)}
          </Heading>
        </Box>
      </Box>
    </Link>
  );
}

StoryPreview.propTypes = {
  story: StoryType.isRequired,
};

export default StoryPreview;
