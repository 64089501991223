import React from "react";
import MainLayout from "../components/layout/main";
import StoryList from "../components/stories/storyList";
import SEO from "../components/SEO";
import { useRegisterScreen } from "../util/firebaseFunctions";

const IndexPage = () => {
  useRegisterScreen('Home');
  return (
    <MainLayout>
      <SEO/>
      <StoryList />
    </MainLayout>
  );
}

export default IndexPage;
