import React from "react";
import { Box, SimpleGrid } from "@chakra-ui/core";
import { graphql, StaticQuery } from "gatsby";
import StoryPreview from "./storyPreview";
import { MainHeading } from "../headings";
import { useIntl } from "gatsby-plugin-intl";

const getStoriesFromPlaces = (allPlace) => {
  const stories = [];
  allPlace.nodes.forEach((place) => {
    if (place.story) {
      const story = {
        title: place.story_title,
        title_en: place.story_title_en,
        category: place.name,
        category_en: place.name_en,
        id: place.id,
      };
      if (place.story_photo) {
        story.photo = place.story_photo;
      } else if (place.photos && place.photos.length > 0) {
        story.photo = place.photos[0].reference;
      }
      if (place.fields && place.fields.slugStory) {
        story.fields = { slug: place.fields.slugStory };
      }
      stories.push(story);
    }
  });
  return stories;
};

const getStories = (allStory, allPlace) => {
  const stories = getStoriesFromPlaces(allPlace);
  stories.push(...allStory.nodes);
  return stories;
};

const shuffle = (elements) => {
  let currentIndex = elements.length, temporaryValue, randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = elements[currentIndex];
    elements[currentIndex] = elements[randomIndex];
    elements[randomIndex] = temporaryValue;
  }
  return elements;
};

const StoryListComponent = ({ data }) => {
  const { allStory, allPlace } = data;
  const [stories, setStories] = React.useState([]);
  const intl = useIntl();
  React.useEffect(() => {
    const theStories = getStories(allStory, allPlace);
    setStories(shuffle(theStories));
  }, [allStory, allPlace]);
  if (stories.length > 0) {
    return (
      <Box>
        <MainHeading>
          {intl.formatMessage({ id: "stories.title" })}
        </MainHeading>
        <SimpleGrid columns={[1, 1, 2, 2]} spacing={8}>
          {stories.map(story => (
            <StoryPreview key={story.id} story={story} />
          ))}
        </SimpleGrid>
      </Box>
    );
  }
  return null;
};

const query = graphql`
  query {
    allStory {
      nodes {
        category
        id
        title
        photo
        fields {
          slug
        }
        category_en
        title_en
      }
    }
    allPlace {
      nodes {
        id
        name
        photos {
          reference
        }
        story
        story_photo
        story_title
        fields {
          slugStory
        }
        name_en
        story_title_en
      }
    }
  }
`;

const StoryList = () => (
  <StaticQuery
    query={query}
    render={data => <StoryListComponent data={data} />}
  />
);

export default StoryList;
